var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accordion", class: { expanded: _vm.is_expanded } },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "span",
            {
              staticClass: "collapse",
              class: { disabled: !_vm.$slots.default },
              on: { click: _vm.toggleExpanded },
            },
            [
              _vm.$slots.default
                ? _c("svgicon", {
                    staticClass: "triangle",
                    attrs: { name: "triangle" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._t("header", null, { is_expanded: _vm.is_expanded }),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.is_expanded,
              expression: "is_expanded",
            },
          ],
          staticClass: "accordion-body",
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }