var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      [
        _c(
          "el-row",
          { attrs: { gutter: 12 } },
          [
            _c(
              "el-col",
              { attrs: { sm: 24, md: 12, lg: 6 } },
              [
                _c("widget-card", { attrs: { loading: _vm.loading } }, [
                  _c("div", { staticClass: "key-value" }, [
                    _c("label", [_vm._v("Linked Epics")]),
                    _c("span", [_vm._v(" " + _vm._s(_vm.num_epics) + " ")]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { sm: 24, md: 12, lg: 6 } },
              [
                _c("widget-card", { attrs: { loading: _vm.loading } }, [
                  _c("div", { staticClass: "key-value" }, [
                    _c("label", [_vm._v("Tasks")]),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.num_tasks) +
                          " / " +
                          _vm._s(_vm.num_subtasks) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { sm: 24, md: 12, lg: 6 } },
              [
                _c("widget-card", { attrs: { loading: _vm.loading } }, [
                  _c("div", { staticClass: "filters" }, [
                    _c("span", [_vm._v("Users")]),
                    _c(
                      "div",
                      { staticClass: "users" },
                      [
                        !_vm.filter_users.length
                          ? [
                              _vm._l(Array(6).fill(), function (_, idx) {
                                return _c("div", {
                                  key: idx,
                                  staticClass: "avatar",
                                })
                              }),
                              _c("div", { staticClass: "overlay" }),
                            ]
                          : _vm._l(_vm.filter_users, function (obj) {
                              return _c(
                                "div",
                                {
                                  key: obj.user.id,
                                  staticClass: "avatar",
                                  class: obj.status,
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleUser(obj)
                                    },
                                  },
                                },
                                [_c("avatar", { attrs: { user: obj.user } })],
                                1
                              )
                            }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { sm: 24, md: 12, lg: 6 } },
              [
                _c(
                  "widget-card",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm
                          .seconds2hours(_vm.total_time_spent)
                          .toFixed(4),
                        expression:
                          "\n                        seconds2hours(total_time_spent).toFixed(4)\n                    ",
                        arg: "copy",
                      },
                    ],
                    staticClass: "total-hours",
                    attrs: { loading: _vm.loading },
                    nativeOn: {
                      click: function ($event) {
                        _vm.Notification({
                          type: "success",
                          title: "Copied",
                          message: `${_vm
                            .seconds2hours(_vm.total_time_spent)
                            .toFixed(4)} hours copied to clipboard.`,
                        })
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "key-value" }, [
                      _c("label", [_vm._v("Time spent / estimated hours")]),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("seconds2duration")(_vm.total_time_spent)
                            ) +
                            " / " +
                            _vm._s(
                              _vm._f("milli2duration")(_vm.total_time_estimated)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("section", [
      _c(
        "div",
        { staticClass: "groups" },
        [
          _vm._l(_vm.mapped_data, function (group) {
            return _c(
              "accordion",
              {
                key: group.id,
                staticClass: "epic-accordion",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function ({ is_expanded }) {
                        return [
                          _c("component-tracked-issue", {
                            attrs: {
                              title: group.title,
                              issue_category: "Epic",
                              linked_issue: group.issue,
                              time_spent: group.time_spent,
                              time_estimate: group.time_estimate,
                              show_column_labels: is_expanded,
                              start_date: group.start_date,
                              end_date: group.end_date,
                              can_bind_issue: "",
                            },
                            on: {
                              bind: (issue) => _vm.bindIssue(group, issue),
                              unbind: () => _vm.unbindIssue(group),
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c(
                  "div",
                  { staticClass: "task-container" },
                  _vm._l(group.tasks, function (task) {
                    return _c(
                      "accordion",
                      {
                        key: task.id,
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("component-tracked-issue", {
                                    attrs: {
                                      title: task.description,
                                      issue_category: task.jira_category,
                                      linked_issue: _vm.issueFor(
                                        task.linked_issue
                                      ),
                                      time_spent: task.time_spent,
                                      time_estimate: task.time_estimate,
                                      start_date: task.start_date,
                                      end_date: task.end_date,
                                      can_bind_issue: "",
                                    },
                                    on: {
                                      bind: (issue) =>
                                        _vm.bindIssue(task, issue),
                                      unbind: () => _vm.unbindIssue(task),
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      _vm._l(task.subtasks, function (subtask) {
                        return _c(
                          "div",
                          { key: subtask.id, staticClass: "subtask" },
                          [
                            _c("component-tracked-issue", {
                              attrs: {
                                title: subtask.description,
                                issue_category: subtask.jira_category,
                                linked_issue: subtask.issue,
                                time_spent: subtask.time_spent,
                                time_estimate: subtask.time_estimate,
                                start_date: subtask.start_date,
                                end_date: subtask.end_date,
                                can_bind_issue: "",
                              },
                              on: {
                                bind: (issue) => _vm.bindIssue(subtask, issue),
                                unbind: () => _vm.unbindIssue(subtask),
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  }),
                  1
                ),
              ]
            )
          }),
          _vm.issues_to_sessions[_vm.UNMATCHED_SESSIONS]?.length
            ? [
                _c(
                  "accordion",
                  {
                    staticClass: "epic-accordion",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function ({ is_expanded }) {
                            return [
                              _c("component-tracked-issue", {
                                attrs: {
                                  title: "Unmatched Sessions",
                                  issue_category: "Epic",
                                  linked_issue: null,
                                  time_spent: _vm.total_untracked_time_spent,
                                  show_column_labels: is_expanded,
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3304205231
                    ),
                  },
                  _vm._l(
                    _vm.issues_to_sessions[_vm.UNMATCHED_SESSIONS],
                    function (session) {
                      return _c(
                        "div",
                        { key: session.id, staticClass: "unmatched-session" },
                        [
                          _c("component-tracked-issue", {
                            attrs: {
                              session: session,
                              title: session.note || "",
                              user_id: session.user,
                              time_spent: _vm.time_spent_on_session(session),
                              linked_issue: null,
                            },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }