var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "adjustment-row" }, [
    _c("div", { staticClass: "block" }, [_vm._v(_vm._s(_vm.adjustment.label))]),
    _c("div", { staticClass: "description" }, [
      _vm._v(" " + _vm._s(_vm.adjustment.description) + " "),
    ]),
    _c("div", { staticClass: "hours" }, [
      _vm._v(_vm._s(_vm._f("milli2duration")(_vm.adjustment.time)) + " hours"),
    ]),
    _c(
      "div",
      { staticClass: "remove" },
      [
        _c(
          "el-popconfirm",
          {
            attrs: { title: "Delete this time adjustment?" },
            on: {
              confirm: function ($event) {
                return _vm.removeAdjustment()
              },
            },
          },
          [
            _c("i", {
              staticClass: "el-icon-circle-close",
              attrs: { slot: "reference" },
              slot: "reference",
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }