var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "component-tracked-issue": true,
        epic: _vm.issue_category === "Epic",
        "show-column-labels": !!_vm.show_column_labels,
      },
    },
    [
      _c(
        "div",
        { staticClass: "icon" },
        [
          _vm.issue_category
            ? _c("svgicon", {
                staticClass: "issue-icon",
                class: _vm.ISSUE_ICONS[_vm.issue_category],
                attrs: { name: _vm.ISSUE_ICONS[_vm.issue_category] },
              })
            : _vm.user
            ? _c("avatar", { attrs: { user: _vm.user } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "description-section" },
        [
          _vm.session
            ? _c("session-input", {
                staticClass: "el-input session-input",
                attrs: {
                  placeholder: "What did you work on?",
                  session: _vm.session,
                  simple_responsive_design: true,
                },
              })
            : _c("span", { staticClass: "description" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
        ],
        1
      ),
      _vm.session
        ? _c("section", { staticClass: "date-section" }, [
            _c("span", { staticClass: "session-date" }, [
              _vm._v(
                " " +
                  _vm._s(_vm._f("dateformat")(_vm.session.start.toDate())) +
                  " "
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "section",
        { staticClass: "issue-section" },
        [
          _vm.linked_issue
            ? [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    {
                      staticClass: "clickable",
                      on: { click: _vm.navigateToIssue },
                    },
                    [
                      _c("issue-item-compact", {
                        attrs: { data: _vm.linked_issue, show_summary: false },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "icon remove",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("unbind")
                        },
                      },
                    },
                    [_c("svgicon", { attrs: { name: "cross" } })],
                    1
                  ),
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("span", [_vm._v(_vm._s(_vm.display_start_date))]),
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("span", { staticClass: "separator" }, [_vm._v("→")]),
                  _c("span", [_vm._v(_vm._s(_vm.display_end_date))]),
                ]),
              ]
            : _vm.can_bind_issue
            ? [
                _c(
                  "div",
                  { staticClass: "issue-search-container" },
                  [
                    _c("issue-search", {
                      on: { select: (issue) => _vm.$emit("bind", issue) },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("section", { staticClass: "time-section" }, [
        _c("div", { staticClass: "column", attrs: { title: "Spent" } }, [
          _c("span", { class: { empty: !_vm.time_spent } }, [
            _vm._v(" " + _vm._s(_vm.display_time_spent) + " "),
          ]),
        ]),
        _c("div", { staticClass: "column", attrs: { title: "Estimated" } }, [
          _c("span", { staticClass: "separator" }, [_vm._v("/")]),
          _c("span", { class: { empty: !_vm.time_estimate } }, [
            _vm._v(" " + _vm._s(_vm.display_time_estimate) + " "),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "column column-difference",
            attrs: { title: "Difference" },
          },
          [
            _c(
              "div",
              {
                class: {
                  frame: true,
                  "under-estimate":
                    !_vm.consumed_over_estimate && _vm.time_spent,
                  "over-estimate": _vm.consumed_over_estimate,
                },
              },
              [
                _vm.time_spent
                  ? _c("span", { staticClass: "sign" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.consumed_over_estimate ? "+" : "−") +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c("span", { class: { empty: !_vm.time_spent } }, [
                  _vm._v(" " + _vm._s(_vm.display_time_difference) + " "),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }