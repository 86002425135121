var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { directives: [{ name: "only-admin", rawName: "v-only-admin" }] },
    [
      _vm.component.batch_count
        ? _c("el-alert", {
            attrs: {
              type: "error",
              closable: false,
              title: `A batch operation is currently being performed on this component (${_vm.component.batch_count})`,
            },
          })
        : _vm._e(),
      _c(
        "content-block",
        { attrs: { title: "Settings" } },
        [
          _c(
            "el-form",
            {
              attrs: { "label-width": "120px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Name" } },
                [
                  _c("el-input", {
                    on: { change: (v) => _vm.updateComponent("name", v) },
                    model: {
                      value: _vm.component.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.component, "name", $$v)
                      },
                      expression: "component.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Reference" } },
                [
                  _c("el-input", {
                    on: {
                      change: (v) =>
                        _vm.updateComponent("ref", v?.toUpperCase()),
                    },
                    model: {
                      value: _vm.component.ref,
                      callback: function ($$v) {
                        _vm.$set(_vm.component, "ref", $$v)
                      },
                      expression: "component.ref",
                    },
                  }),
                ],
                1
              ),
              _c("hr"),
              _c(
                "el-form-item",
                { attrs: { label: "Status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Select" },
                      on: { change: (v) => _vm.updateComponent("status", v) },
                      model: {
                        value: _vm.component.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.component, "status", $$v)
                        },
                        expression: "component.status",
                      },
                    },
                    _vm._l(_vm.status_options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("hr"),
              _c(
                "el-form-item",
                { attrs: { label: "Users" } },
                [
                  _c("user-selector", {
                    attrs: {
                      users: _vm.project_users,
                      selected: _vm.component.users,
                    },
                    on: {
                      change: (v) => _vm.updateComponent("users", v),
                      add_user: _vm.handleAddUser,
                    },
                  }),
                ],
                1
              ),
              _c("hr"),
              _vm.component.recurring && _vm.updated.round > 0
                ? _c("el-form-item", { attrs: { label: "Round time to" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.updated.round) +
                        " " +
                        _vm._s(
                          _vm._f("pluralize")("minute", _vm.updated.round)
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              !_vm.component.recurring
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Round time to" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "minutes", clearable: "" },
                          on: {
                            change: (value) =>
                              _vm.updateComponentMinutes("round", value),
                          },
                          model: {
                            value: _vm.updated.round,
                            callback: function ($$v) {
                              _vm.$set(_vm.updated, "round", $$v)
                            },
                            expression: "updated.round",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("pluralize")(
                                    "minute",
                                    _vm.updated.round
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.component.recurring &&
              _vm.updated.round > 0 &&
              _vm.updated.min > 0
                ? _c("el-form-item", { attrs: { label: "Round after" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.updated.min) +
                        " " +
                        _vm._s(_vm._f("pluralize")("minute", _vm.updated.min)) +
                        " "
                    ),
                  ])
                : _vm._e(),
              !_vm.component.recurring
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Round after" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "minutes",
                            clearable: "",
                            disabled: _vm.component.recurring,
                          },
                          on: {
                            change: (value) =>
                              _vm.updateComponentMinutes("min", value),
                          },
                          model: {
                            value: _vm.updated.min,
                            callback: function ($$v) {
                              _vm.$set(_vm.updated, "min", $$v)
                            },
                            expression: "updated.min",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("pluralize")("minute", _vm.updated.min)
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.component.recurring
                ? [
                    _c("hr"),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Start Date" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            value: _vm.computed_start_date,
                            type: "date",
                            format: "dd/MM/yyyy",
                          },
                          on: {
                            input: (v) => _vm.updateComponent("start_date", v),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "End Date" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            value: _vm.computed_end_date,
                            type: "date",
                            format: "dd/MM/yyyy",
                          },
                          on: {
                            input: (v) => _vm.updateComponent("end_date", v),
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm.component.recurring
        ? _c(
            "content-block",
            { staticClass: "recurring", attrs: { title: "Recurring" } },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-width": "120px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("el-form-item", { attrs: { label: "Period" } }, [
                    _c("span", [
                      _vm._v(
                        " Every " +
                          _vm._s(_vm.component.recurring_config.period_size) +
                          " " +
                          _vm._s(
                            _vm._f("pluralize")(
                              _vm.component.recurring_config.period_unit,
                              _vm.component.recurring_config.period_size
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "Start date" } }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("dateformat")(
                              _vm.component.start_date,
                              "ddd DD/MM/YYYY"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "End date" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          value: _vm.computed_end_date,
                          type: "date",
                          format: "dd/MM/yyyy",
                          "picker-options": _vm.recurring_picker_options,
                        },
                        on: {
                          input: (v) =>
                            _vm.updateComponent(
                              "end_date",
                              _vm.dayjs(v).endOf("day").toDate()
                            ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Time per block",
                        prop: "update.time_estimate",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "hours", clearable: "" },
                          on: {
                            change: (value) =>
                              _vm.updateTime("time_estimate", value),
                          },
                          model: {
                            value: _vm.updated.time_estimate,
                            callback: function ($$v) {
                              _vm.$set(_vm.updated, "time_estimate", $$v)
                            },
                            expression: "updated.time_estimate",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("pluralize")(
                                    "hour",
                                    _vm.updated.time_estimate
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Expires after" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "count", clearable: "" },
                          on: {
                            change: (value) => _vm.updateRecurringExpiry(value),
                          },
                          model: {
                            value: _vm.updated.expires_in,
                            callback: function ($$v) {
                              _vm.$set(_vm.updated, "expires_in", $$v)
                            },
                            expression: "updated.expires_in",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("pluralize")(
                                    "period",
                                    _vm.updated.expires_in
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        2
                      ),
                      !_vm.updated.expires_in || _vm.updated.expires_in === "0"
                        ? _c("span", { staticClass: "field-footnote" }, [
                            _vm._v(
                              " Time allocated to future blocks will not expire. "
                            ),
                          ])
                        : _c("span", { staticClass: "field-footnote" }, [
                            _vm._v(
                              " Time allocated to future blocks will expire after "
                            ),
                            _c("b", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.updated.expires_in *
                                      _vm.component.recurring_config.period_size
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm._f("pluralize")(
                                      _vm.component.recurring_config
                                        .period_unit,
                                      _vm.updated.expires_in *
                                        _vm.component.recurring_config
                                          .period_size
                                    )
                                  )
                              ),
                            ]),
                            _vm._v(". "),
                          ]),
                    ],
                    1
                  ),
                  _c("hr"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Adjustments" } },
                    [
                      _c(
                        "div",
                        { staticClass: "adjustment-form" },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { flex: "unset", width: "160px" },
                              attrs: { placeholder: "Select a block" },
                              model: {
                                value: _vm.adjustment.block,
                                callback: function ($$v) {
                                  _vm.$set(_vm.adjustment, "block", $$v)
                                },
                                expression: "adjustment.block",
                              },
                            },
                            _vm._l(_vm.block_list, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.id },
                              })
                            }),
                            1
                          ),
                          _c("el-input", {
                            staticStyle: { flex: "1" },
                            attrs: {
                              placeholder: "description",
                              clearable: "",
                            },
                            model: {
                              value: _vm.adjustment.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.adjustment, "description", $$v)
                              },
                              expression: "adjustment.description",
                            },
                          }),
                          _c(
                            "el-input",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { placeholder: "hours", clearable: "" },
                              model: {
                                value: _vm.adjustment.time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.adjustment, "time", $$v)
                                },
                                expression: "adjustment.time",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("pluralize")(
                                        "hour",
                                        _vm.adjustment.time
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            2
                          ),
                          _c("el-button", {
                            attrs: {
                              icon: "el-icon-plus",
                              disabled: !_vm.adjustment_valid,
                            },
                            on: { click: _vm.addAdjustment },
                          }),
                        ],
                        1
                      ),
                      _vm._l(_vm.adjustment_list, function (a, index) {
                        return _c("component-adjustment", {
                          key: index,
                          attrs: { adjustment: a },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.component.recurring
        ? _c(
            "content-block",
            { attrs: { title: "Time" } },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-width": "120px" },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Spent" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            value: _vm.$options.filters.milli2duration(
                              _vm.total_spent
                            ),
                            disabled: "",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("pluralize")(
                                    "hour",
                                    _vm.$options.filters.milli2hours(
                                      _vm.total_spent
                                    )
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Available",
                        prop: "update.time_estimate",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "Insert number of hours",
                            clearable: "",
                          },
                          on: {
                            change: (value) =>
                              _vm.updateTime("time_estimate", value),
                          },
                          model: {
                            value: _vm.updated.time_estimate,
                            callback: function ($$v) {
                              _vm.$set(_vm.updated, "time_estimate", $$v)
                            },
                            expression: "updated.time_estimate",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("pluralize")(
                                    "hour",
                                    _vm.updated.time_estimate
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.$store.getters.is_xero_enabled
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Invoiced" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                value: _vm.$options.filters.hours2duration(
                                  _vm.invoiced_hours
                                ),
                                disabled: "",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("pluralize")(
                                        "hour",
                                        _vm.invoiced_hours
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.sorted_time_changes
                    ? [
                        _c("hr"),
                        _c("el-form-item", { attrs: { label: "Changes" } }, [
                          _c(
                            "ul",
                            { staticClass: "time-change" },
                            _vm._l(_vm.sorted_time_changes, function (
                              change,
                              index
                            ) {
                              return _c("time-change-block", {
                                key: `time_change_${index}`,
                                attrs: { data: change },
                              })
                            }),
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$store.getters.is_xero_enabled
        ? _c(
            "content-block",
            { attrs: { title: "Linked invoices" } },
            [
              _vm.sorted_invoices.length
                ? _c(
                    "ul",
                    { staticClass: "invoices-list" },
                    _vm._l(_vm.sorted_invoices, function (invoice) {
                      return _c("invoice-block", {
                        key: invoice.id,
                        attrs: { invoice: invoice },
                      })
                    }),
                    1
                  )
                : _c("el-alert", {
                    attrs: {
                      description: "No invoices linked to this component yet.",
                      closable: false,
                    },
                  }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }