var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.user.id, staticClass: "user-block" }, [
    _c(
      "header",
      [
        _c("avatar", { attrs: { user: _vm.user, live: _vm.is_active } }),
        _c("span", [_vm._v(_vm._s(_vm.user.name))]),
        _c("span", { staticClass: "total" }, [
          _vm._v(" Total: "),
          _c("strong", [
            _vm._v(_vm._s(_vm._f("milli2duration")(_vm.total_duration * 1000))),
          ]),
          _vm.has_reported_hours_rounding && _vm.total_time_reportable_for_user
            ? _c("span", [
                _vm._v(" / "),
                _c("strong", { staticClass: "reportable" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("milli2duration")(
                          _vm.total_time_reportable_for_user
                        )
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
    _c(
      "ul",
      _vm._l(_vm.sessions, function (session) {
        return _c("session-activity-block", {
          key: session.id,
          attrs: { session: session, in_group: false, stop: session.stop },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }