var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "actions-buttons",
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.tabChanged },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "Overview", name: "_overview" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "Activity", name: "_activity" },
              }),
              _vm.user_is_admin
                ? _c("el-tab-pane", {
                    attrs: { label: "Settings", name: "_settings" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.activeTab == "_overview"
            ? _c("ComponentTab_overview", { key: "overview" })
            : _vm.activeTab == "_activity"
            ? _c("ComponentTab_activity", { key: "activity" })
            : _vm.activeTab == "_settings"
            ? _c("ComponentTab_settings", { key: "settings" })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }