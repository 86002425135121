var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "time-change-block" },
    [
      _c("avatar", {
        attrs: { user: _vm.user, size: "mini", hide_tooltip: "" },
      }),
      _c("span", [
        _vm.is_changed_by_user
          ? _c("span", [_vm._v(" " + _vm._s(_vm.user.name) + " ")])
          : _vm.is_changed_by_change_request
          ? _c(
              "label",
              {
                staticClass: "clickable",
                on: { click: _vm.openChangeRequest },
              },
              [_vm._v(" " + _vm._s(_vm.change_request.ref) + " ")]
            )
          : _c("label", [_vm._v(" A change request ")]),
        _vm._v(" changed "),
        _c("label", [_vm._v(_vm._s(_vm.key))]),
        _vm._v(
          " " +
            _vm._s(
              _vm._f("timeAgo")(
                _vm._f("timestamp2moment")(_vm.data.created_at.seconds)
              )
            ) +
            " from "
        ),
        _vm.data.from === null
          ? _c("label", [_vm._v("not set")])
          : _c("label", [
              _vm._v(_vm._s(_vm._f("milli2duration")(_vm.data.from))),
            ]),
        _vm._v(" to "),
        _vm.data.to === null
          ? _c("label", [_vm._v("not set")])
          : _c("label", [
              _vm._v(_vm._s(_vm._f("milli2duration")(_vm.data.to))),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }