var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editor
    ? _c(
        "div",
        {
          staticClass: "editor issue-search",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              !_vm.show_editor
                ? [
                    _c("add-issue-tag-button", {
                      on: { click: _vm.showEditor },
                    }),
                  ]
                : _c("editor-content", {
                    directives: [
                      {
                        name: "shortkey",
                        rawName: "v-shortkey.avoid",
                        modifiers: { avoid: true },
                      },
                    ],
                    ref: "editor_content",
                    staticClass: "editor-content",
                    attrs: { editor: _vm.editor },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.handleKeyUp.apply(null, arguments)
                      },
                    },
                  }),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }